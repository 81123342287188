import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = ['dubai_expo', 'eneos', 'kukan', 'nishikadoma', 'salone2018', 'fuerza_bruta'];

const ProfileContent = () => {
  return (
    <React.Fragment>
      <div className="detail">
        <p>
          CG デザイナー、VFX ディレクターを経て、プロモーション映像やインスタレーションの演出家として活動。<br />
          ドバイ万博日本館、ミラノデザインウィーク、モーターショウなど、国内外のプロジェクトに参加。
        </p>
      </div>
      <ul className="links">
        <li>
          <a href="https://masiochiaio.tumblr.com/" target="_blank" rel="noreferrer">OCHIAI Masao Portfolio ↗</a>
        </li>
      </ul>
    </React.Fragment>
  );
};

const AwardsContent = () => {
  return (
    <Container fluid className="memberAwards">
      <Row>
        <Col>
          <h3 className="titleTopBorder">Awards</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="awardsTitles">
            <Col xs={6} lg={6} className="d-none d-lg-block titleLabel">Works</Col>
            <Col xs={6} lg={6} className="d-none d-lg-block titleLabel">Award title</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">2020年ドバイ国際博覧会 日本館　総合演出</Col>
            <Col xs={6} lg={6} className="col">BIE Prizes and Awards<br />Exhibition Design Gold Award</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">パナソニック創業100周年「ビジョンシアター」</Col>
            <Col xs={6} lg={6} className="col">映文連アワード2019 部門優秀賞 コーポレート・コミュニケーション部門</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">Milano Salone 2018「Air Inventions」 演出</Col>
            <Col xs={6} lg={6} className="col">Milano Design Award 2018 ベストテクノロジー賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">CES 2018 Panasonice Booth「Meet Mr. Matsushita」 演出</Col>
            <Col xs={6} lg={6} className="col">映文連アワード2018 経済産業大臣賞、i賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">「FUERZA BRUTA WA! 空間映像」 コンテンツ/空間演出</Col>
            <Col xs={6} lg={6} className="col">DIGITAL SIGNAGE AWARD 2018</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">Milano Salone 2017「Electronics Meets Crafts:」演出</Col>
            <Col xs={6} lg={6} className="col">Milano Design Award 2017 ベストストーリーテリング賞<br />映文連アワード2017 経済産業大臣賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">Milano Salone 2016「“KUKAN” The Invention of Space」演出</Col>
            <Col xs={6} lg={6} className="col">Milano Design Award 2016 ピープルズチョイス賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">Information Revolution ソフトバンク「創立30周年事業」ビジョン映像 VFX</Col>
            <Col xs={6} lg={6} className="col">映文連アワード2011 部門優秀賞 コーポレート・コミュニケーション部門</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
